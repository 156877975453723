<template>
  <div>
    <!-- about-->
    <div class="team agileinfo">
      <div class="container">
        <h3 class="agileits-title" style="margin-bottom: 1em;">
          《灯塔产品》二维码合作伙伴机制
        </h3>
        <div class="col-sm-6 about-w3right">
          <img
            src="./../../../../Public/utils/new-index/images/co1.jpg"
            alt=""
            width="300px"
          />
        </div>

        <div class="col-sm-6 about-w3left co-text">
          <h4>成为我们的合作伙伴：</h4>
          <p>
            <span>为了让更多的人受益，基于</span
            ><span><strong>“共创共赢”</strong></span
            ><span>的理念，我们团队开发了基于二维码的合作伙伴系统。</span>
          </p>
          <p>
            一旦任何个人或者机构注册成为“机构合作者”，就可以成为我们的合作方，并获得一个全球唯一二维码（如图）作为您身份的标志。
          </p>
          <p>
            任何其他第三方或者个人通过这个二维码扫描并完成测评，机构合作者就会得到我公司的<a
              ><strong>提成奖励</strong></a
            >。可以通过系统实时查看测评者的测评状态与付费状态，测评用户的付费信息可作为合作机构的提成依据，按照已付费人数获得提成奖励。奖励办法按照我公司的“<a
              ><strong>产品代理合作协议</strong></a
            >”执行。
          </p>
          <a
            class="button button-isi"
            href="https://career-explore.com/institution"
            ><span>立即注册</span><i class="el-icon-right"></i></a
          ><a
            class="button button-isi"
            style="margin-left: 0.5em;"
            ><span>查看《灯塔伙伴系统使用步骤》</span
            ><i class="el-icon-right"></i
          ></a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="gallery" style="padding-top: 0;">
      <div class="container">
        <h3 class="agileits-title">机构系统截图</h3>
        <div class="gallery-agileinfo">
          <div class="col-sm-6 col-xs-6 w3gallery-grids">
            <a
              class="w3effct-agile"
              href="./../../../../Public/utils/new-index/images/co2.png"
              ><img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/co2.png"
                alt=""
                title="数据统计页"
              />
              <div class="agile-figcap">
                <h4>数据统计页</h4>
                <p>将统计您的用户数据</p>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-xs-6 w3gallery-grids">
            <a
              class="w3effct-agile w3effct-agile-mdl"
              href="./../../../../Public/utils/new-index/images/co3.png"
              ><img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/co3.png"
                alt=""
                title="数据统计页"
              />
              <div class="agile-figcap">
                <h4>数据统计页</h4>
                <p>将统计您的用户数据</p>
              </div>
            </a>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <!-- //about-team-->
  </div>
</template>

<script>
export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {},
  methods: {
  }
}
</script>

<style lang="less">
</style>
